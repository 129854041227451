@import "../../scss/vars.scss";

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 1;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modal-content {
  width: 100vw;
  transition: all 0.3s ease-in-out;
  transform: translateY(-200px);
  color: $white;

  @media screen and (min-width:$md) {
    width: 80vw;
  }

  h3 {
    margin: 80px 0 30px;
  }

  @media screen and (max-width:$md) {
    h2 {
      font-size: 1.3rem;
      line-height: 1.3rem;
    }
  }

  .category {
    margin-top: 30px;
  }

  .button-close {
    position: absolute;
    top: -30px;
    right: 50px;
    padding: 14px 20px;
    border: none;
    font-size: 1.6rem;
    color: $white !important;
    background-color: $primary;
    z-index: 1;
    cursor: pointer;

    @media screen and (min-width:1200px) {
      top: -30px;
      right: 80px;
    }

    &:hover {
      background-color: $primary-hover;
      transition: background-color 0.3s ease !important;
    }
  }
}

.modal.enter-done .modal-content {
  transform: translateY(0);
}

.modal.exit .modal-content {
  transform: translateY(-200px);
}

.modal-body {
  background-color: $bg-dark;
  max-height: 80vh;
  padding: 10px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 40px;
  border: 1px solid $primary;
  padding: 50px 25px 35px 30px;

  @media screen and (min-width:1200px) {
    padding: 60px;
  }
}