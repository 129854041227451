$black: #333333;
$white: #ffffff;

$primary: #a970ff;
$primary-hover: #8a34e0;

$highlight-green: #ADF562;
$highlight-green-hover: #A5E95D;

$highlight-red: #D13400;
$highlight-red-hover: #B52B00;

$gray-light: #9aa5ad;
$gray: #717C86;
$gray-dark: #6E7884;

$bg-dark: #2C3241;
$bg-dark-alt: #1B1E26;
$bg-white: $white;
$bg-gray: #F9F8FB;

$bg-nav: #F7F6F9;
$font-nav: #919AA4;

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;