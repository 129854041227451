@import "../../scss/vars.scss";

.main-nav {
  background-color: $bg-gray;
  padding: 20px 0;
  position: absolute;
  width: 100%;
  z-index: 1;

  @media screen and (min-width:992px) {
    padding: 20px 0;
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 55px;
    border: none;
    cursor: pointer;
    background-color: $bg-gray;
    color: $black;
  }

  span {
    color: $black;
    margin: 0 10px;
  }
}