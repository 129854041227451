@import "../../scss/vars.scss";

.section-teaser-socials {
  border-top: 1px solid rgba(179, 186, 190, 0.2);
  border-bottom: 1px solid rgba(179, 186, 190, 0.2);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .content {
    grid-column: 2/12;
  }

  .icon-link {
    color: $white;
    font-size: 100px;
    width: 100%;
    height: 100%;
    padding: 35px;
    display: flex;
    justify-content: center;
    transition: color 0.3s ease;
  }

  .x-twitter {
    background: #000000;
  }

  .youtube {
    background: #FF0000;
  }

  .instagram {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(45deg, #F58529, #DD2A7B, #8134AF, #515BD4);
      z-index: -1;
      transition: opacity 0.3s ease;
    }
  }

  .facebook {
    background: #1877F2;
  }

  .twitch {
    background: #9146FF;
  }

  .card-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .card {
    position: relative;
    margin: 15px;
    width: 250px;
    max-height: 250px;
    -webkit-box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
    border: 3px solid white;

    &:hover {
      transition: background 0.3s ease;

      &.x-twitter {
        background: $bg-gray;
        border: 3px solid #000000;

        .icon-link {
          color: #000000;

          span {
            color: #000000;
          }
        }
      }

      &.youtube {
        background: $bg-gray;
        border: 3px solid #FF0000;

        .icon-link {
          color: #FF0000;

          span {
            color: #FF0000;
          }
        }
      }

      &.instagram {
        background: $bg-gray;
        border: 3px solid #DD2A7B;

        &::before {
          opacity: 0;
        }

        .icon-link {
          color: #DD2A7B;

          span {
            color: #DD2A7B;
          }
        }
      }

      &.facebook {
        background: $bg-gray;
        border: 3px solid #1877F2;

        .icon-link {
          color: #1877F2;

          span {
            color: #1877F2;
          }
        }
      }

      &.twitch {
        background: $bg-gray;
        border: 3px solid #9146FF;

        .icon-link {
          color: #9146FF;

          span {
            color: #9146FF;
          }
        }
      }
    }
  }
}