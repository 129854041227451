@import "../../scss/vars.scss";

.icon-bar {
  position: relative;
  -webkit-box-shadow: 0px -5px 15px -5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px -5px 15px -5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px -5px 15px -5px rgba(0, 0, 0, 0.3);

  .content {
    position: relative;
    background-color: $primary;
    padding: 15px 0;


    .icon-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    a {
      margin: 0 10px;
      color: $white;
      font-size: 2rem;

      @media screen and (min-width: $sm) {
        margin: 0 15px;
      }
    }
  }

}